/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

const PrevButton = (props) => (
	<>
		<button {...props} className="slick-arrow slick-prev" type="button">
			Previous
		</button>
		<style jsx>
			{`
				button.slick-prev {
					background: #fff !important;
					box-shadow: 0px 3px 10px rgba(145, 158, 171, 0.1);
					width: 40px;
					height: 40px;
					border-radius: 50%;
					z-index: 1;
					left: 0.1%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				button.slick-prev:before {
					content: '';
					border: solid black;
					border-width: 0 2px 2px 0;
					display: inline-block;
					padding: 5px;
					margin-left: 6px;
					transform: rotate(135deg);
					-webkit-transform: rotate(135deg);
				}
			`}
		</style>
	</>
);

const NextButton = (props) => (
	<>
		<button {...props} className="slick-arrow slick-next" type="button">
			Previous
		</button>
		<style jsx>
			{`
				button.slick-next {
					background: #fff !important;
					box-shadow: 0px 3px 10px rgba(145, 158, 171, 0.1);
					width: 40px;
					height: 40px;
					border-radius: 50%;
					z-index: 1;
					right: 0.1%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				button.slick-next:before {
					content: '';
					border: solid black;
					border-width: 0 2px 2px 0;
					display: inline-block;
					padding: 5px;
					margin-right: 6px;
					transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
				}
			`}
		</style>
	</>
);

const Desktop = ({ ...props }) => {
	// eslint-disable-line no-unused-vars

	const settings = {
		dots: true,
		arrows: true,
		infinite: true,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 5,
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnDotsHover: true,
		nextArrow: <NextButton elem="id_next_slide" />,
		prevArrow: <PrevButton elem="id_prev_slide" />,
		customPaging: () => <div className="ft-slick__dots--custom" />,
	};

	return (
		<>
			<link
				rel="stylesheet"
				type="text/css"
				charSet="UTF-8"
				href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
			/>
			<link
				rel="stylesheet"
				type="text/css"
				href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
			/>
			<div className="section-title">
				<h2 className="panelist-title py-2 px-6 boba-primary-font-h2">
					Meet <span className="black">The</span> Panel{' '}
					<span className="black">Of</span> Panelist
				</h2>
			</div>
			<div className="section-panelist">
				{Array.isArray(props.panelist) ? (
					props.panelist.length > 5 ? (
						<Slider {...settings}>
							{props.panelist.map((item, id) => (
								<div className="panelist-content" key={`panelist-${id + 1}`}>
									<div className="image-wrapper">
										<img
											src={item.image}
											className="card-profile-picture"
											alt="card profile"
										/>
									</div>
									<p className="profile-username boba-primary-font-h5">
										{item.name}
									</p>
									<p className="profile-description boba-primary-font-body-lg-bold">
										{item.position}
									</p>
									<p className="boba-primary-font-body-md-regular">
										Female Daily
									</p>
								</div>
							))}
						</Slider>
					) : (
						<div className="non-slider-panelist-wrapper">
							{props.panelist.map((item, id) => (
								<div className="panelist-content" key={`panelist-${id + 1}`}>
									<div className="image-wrapper">
										<img
											src={item.image}
											className="card-profile-picture"
											alt="card profile"
										/>
									</div>
									<p className="profile-username boba-primary-font-h5">
										{item.name}
									</p>
									<p className="profile-description boba-primary-font-body-lg-bold">
										{item.position}
									</p>
									<p className="boba-primary-font-body-md-regular">
										Female Daily
									</p>
								</div>
							))}
						</div>
					)
				) : null}
			</div>

			<style>
				{`
					.slick-dots {
						margin-top: 54px;
						position: relative;
						bottom: 0;
					}

					.slick-dots li,
					.slick-dots li button,
					.slick-dots li button:before {
						width: unset;
						height: unset;
					}

					.slick-slide div {
						display: flex;
						justify-content: center;
					}

					.section-panelist {
						max-width: 1155px;
						margin: 0 auto;
					}

					.section-panelist .slick-prev {
						top: 25%;
					}
					.section-panelist .slick-next {
						top: 25%;
					}

					.slick-dots li.slick-active .ft-slick__dots--custom {
						height: 10px;
						width: 40px;
						background-color: var(--boba-color-4);
						border-radius: 10px;
						position: relative;
						animation: scale-up-center 0.5s linear;
					}
	
					.slick-dots li .ft-slick__dots--custom {
						height: 10px;
						width: 10px;
						background-color: #fff;
						border-radius: 10px;
						position: relative;
					}
				`}
			</style>
			<style jsx>
				{`
					.section-title {
						display: flex;
						justify-content: center;
						align-items: center;
						margin-bottom: 54px;
					}
					.panelist-title {
						text-align: center;
						font-weight: 400;
						font-style: normal;
						background-color: var(--boba-color-2);
						color: var(--fd-neutral-1);
					}

					.panelist-title .black {
						color: var(--fd-neutral-10);
					}

					.non-slider-panelist-wrapper {
						display: flex;
						justify-content: center;
						gap: 40px;
					}

					.non-slider-panelist-wrapper .panelist-content {
						margin-bottom: 20px;
					}

					.panelist-content {
						max-width: 190px;
					}

					.panelist-content .image-wrapper {
						width: 190px;
						aspect-ratio: 1 / 1;
						border: 2px solid var(--fd-neutral-10);
						overflow: hidden;
						margin-bottom: 6px;
					}

					.panelist-content .profile-username {
						margin-bottom: 8px;
						display: flex;
						align-items: center;
						height: 60px;
					}

					.panelist-content .profile-description {
						margin-bottom: 8px;
					}
				`}
			</style>
		</>
	);
};

export default Desktop;

Desktop.propTypes = {
	panelist: PropTypes.instanceOf(Array).isRequired,
};
