import PropTypes from 'prop-types';
import React from 'react';
import Desktop from './desktop';
import Mobile from './mobile';

const Panelist = ({ isMobile, panelist }) => {
	if (isMobile) {
		return <Mobile panelist={panelist} />;
	}

	return <Desktop panelist={panelist} />;
};

export default Panelist;

Panelist.propTypes = {
	isMobile: PropTypes.bool,
	panelist: PropTypes.instanceOf(Array),
};

Panelist.defaultProps = {
	isMobile: null,
	panelist: [],
};
