import React from 'react';
import PropTypes from 'prop-types';

const Mobile = ({ ...props }) => (
	<>
		<div className="title-container">
			<h4 className="title-panelist py-1 px-2 boba-primary-font-h4">
				Meet <span className="black">The</span> Panel{' '}
				<span className="black">Of</span> Panelist
			</h4>
		</div>
		<div className="panelist-wrapper">
			{Array.isArray(props.panelist)
				? props.panelist.map((item, id) => (
						<div key={`panelist-${id + 1}`} className="panelist-content">
							<div className="image-wrapper">
								<img
									src={item.image}
									className="card-profile-picture"
									alt="card profile"
								/>
							</div>
							<p className="boba-primary-font-h5">{item.name}</p>
							<p className="profile-description boba-primary-font-body-lg-bold">
								{item.position}
							</p>
							<p className="position-description boba-primary-font-body-md-regular">
								Female Daily
							</p>
						</div>
					))
				: null}
		</div>
		<style jsx>
			{`
				.title-container {
					width: 300px;
					margin: 0 auto;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
				}

				.title-panelist {
					font-weight: 400;
					background-color: var(--boba-color-2);
					color: var(--fd-neutral-1);
				}

				.title-panelist .black {
					color: var(--fd-neutral-10);
				}

				.panelist-wrapper {
					display: flex;
					flex-wrap: no-wrap;
					margin-top: 32px;
					overflow-x: scroll;
					-ms-overflow-style: none; /* IE and Edge */
					scrollbar-width: none; /* Firefox */
				}

				.panelist-wrapper::-webkit-scrollbar {
					display: none;
				}

				.panelist-content {
					margin-right: 20px;
				}

				.panelist-content:nth-child(1) {
					margin-left: 24px;
				}

				.panelist-content:nth-last-child(1) {
					margin-right: 24px;
				}

				.panelist-content .image-wrapper {
					width: 160px;
					height: 160px;
					aspect-ratio: 1 / 1;
					border: 2px solid var(--fd-neutral-10);
					margin-right: 0;
					margin-bottom: 10px;
				}

				.panelist-content .card-profile-picture {
					width: 100%;
				}

				.panelist-content .profile-description {
					margin-top: 8px;
				}
				.panelist-content .position-description {
					margin-top: 8px;
				}
			`}
		</style>
	</>
);

export default Mobile;

Mobile.propTypes = {
	panelist: PropTypes.instanceOf(Array).isRequired,
};
